<template>
  <form v-if="allTradingPairs" @submit.prevent="" class="needs-validation" novalidate>

    <div class="row mb-3 justify-content-center">
      <div class="col">
        <div class="form-floating">
          <input v-model="form.title" @change="validateForm()" class="form-control" type="text" placeholder="Title">
          <label for="floatingInput">Title</label>
          <div v-if="formValidations.title" class="invalid-feedback d-block fs-10">{{ formValidations.title }}</div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <div class="form-floating">
          <select v-model="tempForm.tradingPair" @change="updateData()" class="form-select">
            <option v-for="elem in allTradingPairs" :key="elem.symbol" :value="elem">{{ elem.baseCoin }} / {{ elem.quoteCoin }}</option>
          </select>
          <label>
            Trading Pair <span v-if="loadingTradingPairs">(Loading...)</span>
          </label>

          <p class="mb-0 fs-10">
            <span>Assets on this account: </span>
            <span v-if="loadingAssets">Loading...</span>
            <span v-if="!loadingAssets && allAssets && allAssets.length <= 0" class="text-warning">This Bot will not be able to trade if you don't put at least one of the Trading Pair assets into this account. Make sure the funds are on the "Trading/Spot/Unified Trading" account. The "Funding" account cannot trade and thus those funds are not visible here.</span>
            <span v-if="!loadingAssets">
              <span v-for="elem in allAssets" :key="elem.coin">
                <span class="fw-bold">{{ elem.coin }}:</span> {{ getPrettyFloatNumber(elem.balance, 4) }} &nbsp;
              </span>
            </span>
            <br>
          </p>
          <div v-if="!loadingAssets && form.baseCoin && form.quoteCoin" class="fs-10">This Bot will use <span class="fw-bold">{{ form.quoteCoin }}</span> when buying and <span class="fw-bold">{{ form.baseCoin }}</span> when selling.</div>
          <div v-if="formValidations.tradingPair" class="text-danger d-block fs-10 mt-1">{{ formValidations.tradingPair }}</div>
          <div v-if="!loadingAssets && !loadingTradingPairs && tradingPairAssetsWarningText" class="text-warning d-block fs-10 mt-1">{{ tradingPairAssetsWarningText }}</div>
          <div v-if="!loadingAssets && !loadingTradingPairs && getExchangeId() == 'mexc'" class="text-warning d-block fs-10 mt-1">MEXC does not allow certain trading pairs to be traded via their API (e.g <a href="https://www.mexc.com/support/articles/15149585234969" target="_blank">BTC/USDT</a>, <a href="https://www.mexc.com/support/articles/16146292429849" target="_blank">AVAX/USDT</a>, etc). That's why you won't find those pairs in the dropdown.</div>
        </div>
      </div>
    </div>

    <div v-if="bot" class="row">
      <div class="col text-center">

        <div class="">
          <div class="text-start">
            <label>Position Size</label>
            <p class="fs-10">
              You choose the position size when you send the trading signal to SIGNUM (it's not a static setting).<br>
              Scroll down and pick the signal you need.
            </p>
          </div>
        </div>

      </div>
    </div>

    <div v-if="bot" class="row mt-1">
      <div class="col">
        <div class="text-start">
          <label>Allow tools & IPs to trigger this Bot</label>
        </div>

        <div class="form-check form-check-inline">
          <input v-model="form.ipAllowedTradingView" class="form-check-input" type="checkbox" />
          <label class="form-check-label" >TradingView</label>
        </div>

        <div class="form-check form-check-inline">
          <input v-model="form.ipAllowedMake" class="form-check-input" type="checkbox" />
          <label class="form-check-label" >Make</label>
        </div>

      </div>
    </div>

    <div v-if="bot" class="row mt-0 pt-0">
      <div class="col">
        <div class="form-check">
          <input v-model="form.ipAllowedCustom" class="form-check-input" type="checkbox" />
          <label class="form-check-label" >Allow custom IPs to trigger this Bot</label>

          <div v-if="form.ipAllowedCustom">
            <textarea v-model="form.ipAllowedCustomList" class="form-control" style="height: 100px" rows=6></textarea>
            <p class="fs-10 mb-0">Comma separated (e.g. 172.217.1.1, 203.0.113.255, 191.10.123.1)</p>
            <div v-if="formValidations.ipAllowedCustomList" class="invalid-feedback d-block fs-10">{{ formValidations.ipAllowedCustomList }}</div>
          </div>
        </div>
        <div v-if="formValidations.ipAllowedError" class="invalid-feedback d-block fs-10">{{ formValidations.ipAllowedError }}</div>
      </div>
    </div>

    <div v-if="bot && !loading" class="row mt-4">
      <div class="col-4 d-flex justify-content-start mb-2">
        <button @click="back" type="submit" class="btn btn-secondary">Back</button>&nbsp;
      </div>
      <div class="col-8 d-flex justify-content-end mb-2">
        <button @click="save(true)" type="submit" class="btn btn-primary">Save</button>&nbsp;
        <!-- <button @click="save(false)" type="submit" class="btn btn-primary">Save & Back</button> -->
      </div>
    </div>

    <div v-if="!bot && !loading" class="row mt-3">
      <div class="col-4 d-flex justify-content-start mb-2">
        <button @click="cancel" type="submit" class="btn btn-secondary">Cancel</button>&nbsp;
      </div>
      <div class="col-8 d-flex justify-content-end mb-2">
        <button @click="save(false)" type="submit" class="btn btn-primary">Create Bot</button>
      </div>
    </div>

    <div v-if="loading" class="row text-center mt-3">
      <div class="col-12">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

  </form>
</template>

<script>
export default {
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    // For Edit Bot
    bot: {
      required: false,
      type: Object
    },
    // For Create Bot
    exchangeId: {
      required: false,
      type: String
    },
    apiKey: {
      required: false,
      type: String
    },
    apiSecret: {
      required: false,
      type: String
    },
    apiPassphrase: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      loadingAssets: false,
      loadingTradingPairs: false,
      tempForm: {
        tradingPair: null // Object
      },
      form: {
        id: this.bot?.id,
        title: this.bot?.title,
        baseCoin: this.bot?.baseCoin,
        quoteCoin: this.bot?.quoteCoin,
        ipAllowedTradingView: this.bot?.ipAllowedTradingView,
        ipAllowedMake: this.bot?.ipAllowedMake,
        ipAllowedCustom: (this.bot?.ipAllowedCustomList != '') ? true : false,
        ipAllowedCustomList: this.bot?.ipAllowedCustomList
      },
      formValidations: {
        title: '',
        tradingPair: '',
        ipAllowedError: '',
        ipAllowedCustomList: ''
      }
    }
  },
  computed: {
    allTradingPairs () {
      if (this.$store.state.exchangeTradingPairs[this.getExchangeId()] && this.allAssets) {
        let tradingPairs = this.$store.state.exchangeTradingPairs[this.getExchangeId()]

        tradingPairs.sort((a,b) => (a.baseCoin > b.baseCoin) ? 1 : ((b.baseCoin > a.baseCoin) ? -1 : 0))

        return tradingPairs
      }
      return []
    },
    allAssets () {
      return this.$store.state.exchangeAssets
    },
    tradingPairAssetsWarningText() {
      let warning = ""
      if (!this.allAssets) return warning
      if (this.allAssets.length <= 0) return warning

      let assetFound = false
      for (const asset of this.allAssets) {
        if (asset.coin == this.form.baseCoin || asset.coin == this.form.quoteCoin) {
          assetFound = true
          break;
        }
      }
      if (!assetFound) warning = "The trading pair you have chosen, does not match any asset you have on this account. This bot will not be able to trade!"

      return warning
    },
  },
  methods: {
    updateData () {
      // Initialize tempForm
      if (!this.tempForm.tradingPair) {
        const tradingPair = this.allTradingPairs.find(elem => elem.symbol === this.bot?.baseCoin+''+this.bot?.quoteCoin)
        if (tradingPair) {
          this.tempForm.tradingPair = tradingPair
        } else {
          this.form.baseCoin = ''  
          this.form.quoteCoin = ''
        }
      } else {
        // Now take the values from tempForm and update the actual form with it
        this.form.baseCoin = this.tempForm.tradingPair.baseCoin
        this.form.quoteCoin = this.tempForm.tradingPair.quoteCoin
      }

      // Validate the data after updating state
      this.validateForm()
    },
    getExchangeId () {
      if (this.bot?.exchangeId) return this.bot?.exchangeId
      if (this.exchangeId) return this.exchangeId
      return null
    },
    getPrettyFloatNumber (number, numDecimals) {
      let formattedNumber = parseFloat(number).toLocaleString('en-US', {minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals})
      return formattedNumber
    },
    validateForm () {
      let formValid = true
      this.formValidations = {} // Reset validations

      // Validate that the title is valid 
      if (!this.form.title) {
        this.formValidations.title = 'Please give your bot a title.'
        formValid = false
      }

      // Validate that the coins are still available  
      if (!this.form.baseCoin || !this.form.quoteCoin) {
        if (this.bot) { // Edit mode
          this.formValidations.tradingPair = 'This trading pair '+this.bot?.baseCoin+' / '+this.bot?.quoteCoin+' is not supported. Please pick a different one!'
        } else { // Create mode
          this.formValidations.tradingPair = 'Please choose a trading pair.'
        }
        formValid = false
      }

      // Validate if any IP/Tool is allowed to call this bot
      // Clear the IP list when the checkbox is unchecked while the textarea still has a value inside
      if (!this.form.ipAllowedCustom) {
        this.form.ipAllowedCustomList = ''
      }

      // Ensure that at least one IP option is enabled
      if (!this.form.ipAllowedTradingView
      && !this.form.ipAllowedMake
      && this.form.ipAllowedCustomList == '') {
        this.formValidations.ipAllowedError = 'Pick a least one way this Bot can be triggered otherwise no tool can trigger it.'
        formValid = false
      }

      // Ensure the IP list contains actual IPs
      if (this.form.ipAllowedCustomList && this.form.ipAllowedCustomList != '') {
        const regex = /\b(([0-9]{1,3}\.){3}[0-9]{1,3})((?!=|,).)?\b/g
        const validIPv4Addresses = this.form.ipAllowedCustomList.match(regex);

        // Remove spaces from the IP list so we can validate it
        const sanitizedIpV4List = this.form.ipAllowedCustomList.replace(/\s/g, '');
        let validIpCsvList = ''

        // Now prepare the valid IP list string so we can compare with the user input
        if (validIPv4Addresses && validIPv4Addresses.length > 0) {
          validIpCsvList = validIPv4Addresses.join(',')
        }

        if (sanitizedIpV4List != validIpCsvList) {
          this.formValidations.ipAllowedCustomList = 'Some IPs were not valid, please check your IP list for mistakes.'
          formValid = false
        }
      }
      
      return formValid
    },
    async save (stay) {
      if (!this.validateForm()) {
        return false
      }

      let newBot = {
        ...this.form
      }

      this.$emit("save", { newBot, stay });
    },
    back () {
      this.$router.push({ name: 'MyBots' })
    },
    cancel () {
      this.$router.push({ name: 'MyBots' })
    }
  },
  async created () {
  },
  async mounted () {
    const exchangeId = this.getExchangeId()

    // Show loading for both otherwise it looks strange in UI
    this.loadingTradingPairs = true
    this.loadingAssets = true

    if (!this.$store.state.exchangeTradingPairs[exchangeId]) {
      if (this.bot) { // Edit mode
        await this.$store.dispatch('getExchangeTradingPairs', { exchangeId: exchangeId, botId: this.bot?.id })
      } else { // Create mode
        await this.$store.dispatch('getExchangeTradingPairsByApiKeys', { exchangeId: exchangeId, apiKey: this.apiKey, apiSecret: this.apiSecret, apiPassphrase: this.apiPassphrase })
      }
    }

    if (this.bot) { // Edit mode
      // Fetch assets data every time, so that it's always up to date
      this.$store.commit('setExchangeAssets', { assets: null }) // TODO: Is this actually ever loaded?
      await this.$store.dispatch('getExchangeAssets', { exchangeId: exchangeId, botId: this.bot?.id })
    } else { // Create mode
      await this.$store.dispatch('getExchangeAssetsByApiKeys', { exchangeId: exchangeId, apiKey: this.apiKey, apiSecret: this.apiSecret, apiPassphrase: this.apiPassphrase })
    }

    this.loadingTradingPairs = false
    this.loadingAssets = false

    this.updateData()
  },
  unmounted () {
    console.log('umounted')

    // Reset data to be sure it's clean when editing the next bot
    this.loadingAssets = false
    this.loadingTradingPairs = false
    this.tempForm = {}
    this.form = {}
    this.formValidations = {}
  }
}
</script>

<style lang="scss" scoped>

</style>