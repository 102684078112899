<template>
  <TheNavbar />

  <div v-if="justSubscribed" class="row justify-content-center mt-4 mb-4">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
      
      <div class="alert alert-success border-0 d-flex align-items-center mb-0" role="alert">
        <div class="bg-success me-3 icon-item"><span class="fas fa-check-circle text-white fs-6"></span></div>
        <p class="mb-0 flex-1">
          <span class="fw-bold">WELCOME to SIGNUM 🥳</span><br>
          We are thrilled to have you with us ❤️ and wish you good fortune 🙏🏼
        </p>
      </div>

    </div>
  </div>

  <div v-if="userProfile" class="mt-4 mb-5">

    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-4">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Manage my subscription</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary p-0">

            <!-- User has a subscription -->
            <div v-if="userProfile.subscriptionActive && userProfile.stripeProductId" class="row justify-content-center p-4">
              <div class="col-4">
                <img class="img-fluid" src="@/assets/stripe/stripe_logo.png" />
              </div>
              <div class="col-8">
                <p>
                  <span class="fw-bold">You are subscribed</span> 🥳
                </p>
                
                <!-- <p>
                  Your subscription is: <span class="fw-bold">{{ getUserSubscribedProduct()?.title }}</span>
                </p> -->
                <p>
                  We use <span class="fw-bold">Stripe</span> for payment processing.
                </p>
                <p>In the <span class="fw-bold">Stripe Customer Portal</span> you can <span class="fw-bold">download your invoices</span>, <span class="fw-bold">manage your payment methods</span> and <span class="fw-bold">cancel your subscription</span> at any time.</p>

                <a @click="goToStripeCustomerPortal()" target="_blank" class="btn btn-primary btn-sm" type="button">
                  <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
                  Stripe Customer Portal
                </a>
              </div>
            </div> <!-- row -->
            
            <!-- User has NO subscription -->
            <div v-if="userProfile.subscriptionStatus == 'trial' || !userProfile.subscriptionActive" class="row g-0">

              <div class="col-lg-12 border-top border-bottom_ p-3 text-center">

                <div v-if="userProfile.isAutotradingUser" class="mt-3">
                  <div v-if="userProfile?.subscriptionTrialLeftSec == 0">
                    <p>
                      <span class="fw-bold">Your 12-months free usage of SIGNUM has ended.</span><br>
                      Choose a plan from below to continue using SIGNUM.<br>
                      <br>
                      Your trades have stopped executing! Take care 🙏🏼
                    </p>
                  </div>
                  <div v-else-if="userProfile?.subscriptionStatus == 'canceled'">
                    <p>
                      <span class="fw-bold">You subscription has ended!</span><br>
                      <br>
                      Make sure to subscribe again if you want your trades to be executed.
                    </p>  
                  </div>
                  <div v-else-if="userProfile?.subscriptionTrialLeftSec > 1209600">
                    <p>
                      You are an <span class="fw-bold">AutoTrading.vip Member</span>, so you don't need to subscribe until:<br>
                      <span class="fw-bold">{{ getPrettyDateText(userProfile?.subscriptionTrialEndAt) }}</span>
                    </p>
                    <!-- <img class="img-fluid" src="@/assets/subscribe/autotrading_logo.png" /> -->
                  </div>
                  <div v-else>
                    <p class="mt-3">At SIGNUM, we keep it simple. One subscription to rule them all 🤩</p>
                  </div>

                </div>
                <div v-else-if="!userProfile.isAutotradingUser" class="mt-3">
                  <div v-if="isBlackFridayDealActive" class="mb-3">
                    <img class="img-fluid" src="@/assets/subscription/blackfriday2024_v2.png" /><br>
                    <input class="text-center border-0 fw-bold" style="" type="text" value="BLACKFRIDAY2024" />
                  </div>
                  <div v-if="userProfile?.subscriptionTrialLeftSec == 0">
                    <p>
                      <span class="fw-bold">Your 14-day Trial period has has ended.</span>
                      <br>Choose a plan from below to continue using SIGNUM.<br>
                      <br>
                      Your trades have stopped executing! Take care 🙏🏼
                    </p>
                  </div>
                  <div v-else-if="userProfile?.subscriptionStatus == 'canceled'">
                    <p>
                      <span class="fw-bold">You subscription has ended!</span><br>
                      <br>
                      Make sure to subscribe again if you want your trades to be executed.
                    </p>  
                  </div>
                  <div v-else-if="userProfile?.subscriptionTrialLeftSec <= 1209600">
                    <p>
                      You are on a Trial, which will end on:<br>
                      <span class="fw-bold">{{ getPrettyDateText(userProfile?.subscriptionTrialEndAt) }}</span><br>
                      <br>
                      Make sure to subscribe before it ends so your trades don't stop.
                    </p>  
                  </div>
                  <div v-else-if="userProfile?.subscriptionTrialLeftSec > 1209600">
                    <p>
                      You are free to use SIGNUM without subscribing until you've made your first trade.<br>
                      <br>
                      After that your 14-Day Free Trial will start and you can trade freely during that time.<br>
                      Once the Trial is over, you have to subscribe to continue trading.
                    </p>
                  </div>
                </div>
                <div v-else>
                  <p class="mt-3">At SIGNUM, we keep it simple. One subscription to rule them all 🤩</p>
                </div>

              </div>

              <div v-if="userProfile.isAutotradingUser" class="col-lg-6 border-top border-bottom">
                <div class="h-100">
                  <div class="text-center p-4">
                    <h3 class="fw-normal my-0">AutoTrading Monthly (-40%)</h3>
                    <p class="mt-3">
                      As a valued AutoTrading members, you are getting a very special deal 🥳<br>
                      It is -40% cheaper than non AutoTrading members.
                    </p>
                    <h2 class="fw-medium my-4"> <sup class="fw-normal fs-7 me-1">$</sup>15<small class="fs-10 text-700">/ month</small>
                    </h2>
                    <a class="btn btn-primary" :href="getSubscribeUrl('monthly')" target="_blank">
                      <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
                      Subscribe
                    </a>
                  </div>
                  <hr class="border-bottom-0 m-0">
                  <div class="text-start px-4 py-4">
                    <h5 class="fw-medium fs-9">Included:</h5>
                    <ul class="list-unstyled mt-3">
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> max. 25 Bots
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> No other limits
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> Fair use (don't spam)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="userProfile.isAutotradingUser" class="col-lg-6 border-top border-bottom" style="background-color: rgba(115, 255, 236, 0.18);">
                <div class="h-100">
                  <div class="text-center p-4">
                    <h3 class="fw-normal my-0">AutoTrading Yearly (-40%)</h3>
                    <p class="mt-3">
                      As a valued AutoTrading members, you are getting a very special deal 🥳<br>
                      It is -40% cheaper than non AutoTrading members.
                    </p>
                    <h2 class="fw-medium my-4">
                      <sup class="fw-normal fs-7 me-1">$</sup>135<small class="fs-10 text-700">/ year</small>
                    </h2>
                    <a class="btn btn-primary" :href="getSubscribeUrl('yearly')" target="_blank">
                      <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
                      Subscribe
                    </a>
                  </div>
                  <hr class="border-bottom-0 m-0">
                  <div class="text-start px-4 py-4">
                    <h5 class="fw-medium fs-9">Included:</h5>
                    <ul class="list-unstyled mt-3">
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> max. 25 Bots
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> No other limits
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> Fair use (don't spam)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div v-if="!userProfile.isAutotradingUser" class="col-lg-6 border-top border-bottom">
                <div class="h-100">
                  <div class="text-center p-4">
                    <h3 class="fw-normal my-0">BASIC Monthly</h3>
                    <p class="mt-3">Automate your strategies with ease.</p>
                    <h2 class="fw-medium my-4"> <sup class="fw-normal fs-7 me-1">$</sup>25<small class="fs-10 text-700">/ month</small>
                    </h2>
                    <a class="btn btn-primary" :href="getSubscribeUrl('monthly')" target="_blank">
                      <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
                      Get BASIC Monthly
                    </a>
                  </div>
                  <hr class="border-bottom-0 m-0">
                  <div class="text-start px-4 py-4">
                    <h5 class="fw-medium fs-9">Included:</h5>
                    <ul class="list-unstyled mt-3">
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> max. 25 Bots
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> No other limits
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> Fair use (don't spam)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div v-if="!userProfile.isAutotradingUser" class="col-lg-6 border-top border-bottom" style="background-color: rgba(115, 255, 236, 0.18);">
                <div class="h-100">
                  <div class="text-center p-4">
                    <h3 class="fw-normal my-0">BASIC Yearly (-25%)</h3>
                    <p class="mt-3">Automate your strategies with ease.</p>
                    <h2 class="fw-medium my-4">
                      <sup class="fw-normal fs-7 me-1">$</sup>225<small class="fs-10 text-700">/ year</small>
                    </h2>
                    <a class="btn btn-primary" :href="getSubscribeUrl('yearly')" target="_blank">
                      <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
                      Get BASIC Yearly
                    </a>
                  </div>
                  <hr class="border-bottom-0 m-0">
                  <div class="text-start px-4 py-4">
                    <h5 class="fw-medium fs-9">Included:</h5>
                    <ul class="list-unstyled mt-3">
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> max. 25 Bots
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> No other limits
                      </li>
                      <li class="py-1">
                        <span class="me-2 fas fa-check text-success"></span> Fair use (don't spam)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div> <!-- row -->

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'

export default {
  components: {
    TheNavbar
  },
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    },
    justSubscribed () {
      return this.$route.query.justSubscribed
    },
    isBlackFridayDealActive () {
      const expiryDate = new Date(2024, 11, 2, 23, 59, 59)
      const nowDate = new Date()

      return (nowDate.getTime() > expiryDate.getTime()) ? false : true
    }
  },
  methods: {
    getUserSubscribedProduct () {
      const products = {
        prod_QGftbFzc9z4vC9: {
          title: "SIGNUM Basic",
          maxBots: 25
        },
        prod_QaA3DUQkagAvYu: {
          title: "SIGNUM AutoTrading",
          maxBots: 25
        },
        prod_QZ0SXRTFuQaKnK: {
          title: "SIGNUM Basic (Tester)",
          maxBots: 25
        },
        prod_Qa9z62YRbEX0sd: {
          title: "SIGNUM AutoTrading (Tester)",
          maxBots: 25
        }
      }
      const stripeProductId = this.userProfile?.stripeProductId
      return products[stripeProductId]
    },
    async goToStripeCustomerPortal () {
      const stripeCustomerPortalSession = await this.$store.dispatch('getUserStripePortalSession')
      window.open(stripeCustomerPortalSession.url,'_blank');
    },
    getSubscribeUrl (period) {
      const userEmail = this.userProfile?.email
      const userId = this.userProfile?.id

      const urlParams = {
        'prefilled_email': userEmail,
        'client_reference_id': userId
      }
      
      let stripeSubUrl = process.env.VUE_APP_STRIPE_SUB_BASIC_MONTHLY_URL+'?'+this.encodeQueryData(urlParams)
      if (this.userProfile?.isAutotradingUser) {
        if (period == 'monthly') {
          stripeSubUrl = process.env.VUE_APP_STRIPE_SUB_AUTOTRADING_MONTHLY_URL+'?'+this.encodeQueryData(urlParams)  
        } else {
          stripeSubUrl = process.env.VUE_APP_STRIPE_SUB_AUTOTRADING_YEARLY_URL+'?'+this.encodeQueryData(urlParams)
        }
      } else {
        if (period == 'monthly') {
          stripeSubUrl = process.env.VUE_APP_STRIPE_SUB_BASIC_MONTHLY_URL+'?'+this.encodeQueryData(urlParams)
        } else if (period == 'yearly') {
          stripeSubUrl = process.env.VUE_APP_STRIPE_SUB_BASIC_YEARLY_URL+'?'+this.encodeQueryData(urlParams)
        }
      }

      return stripeSubUrl
    },
    encodeQueryData(data) {
      const ret = [];
      for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      return ret.join('&');
    },
    getPrettyDateText (someString) {
      const timestamp = Date.parse(someString);
      const dateObj = new Date(timestamp)
      return dateObj.toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
    },
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })

    if (this.$route.query.justSubscribed) {
      this.$confetti.start()
      setTimeout(() => {
        this.$confetti.stop()
      }, "5000");
    }
  },
  async mounted () {
    // Fetch the sripe portal session when needed (to be sure it's there)
    if (this.userProfile && this.userProfile?.subscriptionActive && this.userProfile?.stripeProductId) {
      if (!this.$store.getters.getUserStripePortalSession) await this.$store.dispatch('getUserStripePortalSession')
    }
  }
  
}
</script>

<style scoped>

</style>